
import { defineComponent } from "vue";

export default defineComponent({
  name: "delete-shifts-modal",
  methods: {
    deleteButtonClicked() {
      this.$emit("delete");
    }
  }
});
