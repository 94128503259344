<template>
  <DeleteShiftModal @delete="flagShiftForDeletion"></DeleteShiftModal>
  <div class="table-responsive">
    <table
      class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
    >
      <thead>
        <tr class="fw-bolder text-muted">
          <th class="min-w-50px w-175px px-9">Day</th>
          <th class="w-100px">Shift Start</th>
          <th class="min-w-100px">Shift End</th>
          <th class="min-w-100px">Duration</th>
          <th class="min-w-100px text-end">
            <span class="me-2">Actions</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="shift in existingShifts" :key="shift.id">
          <tr>
            <td class="px-9">
              <div class="d-flex flex-column">
                <div>
                  <el-date-picker
                    name="date"
                    size="large"
                    v-if="canChangeStartDate(shift.id)"
                    v-model="shift.startDate"
                    :disabled="shift.isDeleted"
                    type="date"
                    placeholder="Select start date"
                    @change="
                      event => handleChangeStart({ id: shift.id, data: event })
                    "
                    :disabled-date="disabledDates"
                    format="ddd DD/MM/YYYY"
                    value-format="DD/MM/YYYY"
                  ></el-date-picker>
                  <span v-else class="fs-6 fw-bold">{{ shift.startDate }}</span>
                </div>
                <span class="fs-7" v-if="shift.isDeleted"
                  >Flagged for delete</span
                >
              </div>
            </td>
            <td>
              <input
                v-model="shift.startHour"
                :disabled="shift.isDeleted"
                @change="changeStart(shift.id)"
                id="startHour_edit"
                class="form-control me-2 w-70px h-40px"
              />
            </td>
            <td>
              <input
                v-model="shift.endHour"
                :disabled="shift.isDeleted"
                @change="changeEnd(shift.id)"
                id="endHour_edit"
                class="form-control me-2 w-70px h-40px"
              />
            </td>

            <td>
              <ShiftDurationWidget
                :shift="shift"
                :isEditingJob="true"
              ></ShiftDurationWidget>
            </td>
            <td class="text-end">
              <a
                class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                data-bs-toggle="collapse"
                :href="'#shiftCollapse' + shift.id"
              >
                <ActionButtonIcon
                  :dynamicClass="'svg-icon-light-primary'"
                  :svgSize="'svg-icon-3'"
                ></ActionButtonIcon>
              </a>
              <DeleteShiftButton
                v-if="!shift.isDeleted && canDeleteShift(shift.id)"
                @click="handleDeleteRequest(shift.id)"
                data-bs-toggle="modal"
                data-bs-target="#deleteShiftModal"
              ></DeleteShiftButton>
              <button
                v-else-if="shift.isDeleted && canDeleteShift(shift.id)"
                class="btn btn-icon btn-bg-success btn-active-color-light btn-sm me-1"
                @click="removeDeleteFlag(shift.id)"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Remove flag for delete."
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                        transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002) "
                      />
                      <path
                        d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002) "
                      />
                    </g></svg
                  ></span
                >
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="12" class="p-0 m-0">
              <div class="collapse in" :id="'shiftCollapse' + shift.id">
                <div class="px-3 mb-4">
                  <BoxShiftSlots
                    :selectedSkills="shift.shiftSlots"
                    :shift="shift"
                    @booked="handleBookButton($event, shift.id)"
                    :localShiftID="shift.id"
                  ></BoxShiftSlots>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <tr class="border-bottom-2">
          <td
            colspan="5"
            class="bg-hover-secondary"
            data-bs-toggle="modal"
            data-bs-target="#addNewShiftModal"
          >
            <div class="d-flex justify-content-center align-items-center">
              <span class="svg-icon svg-icon-3 svg-icon-primary"
                ><svg
                  version="1.1"
                  viewBox="0 0 24 24"
                  height="24px"
                  width="24px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    xmlns="http://www.w3.org/2000/svg"
                    id="Oval-5"
                    fill="#000000"
                    opacity="0.3"
                    cx="12"
                    cy="12"
                    r="10"
                  ></circle>
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                    id="Combined-Shape"
                    fill="#000000"
                  ></path></svg
              ></span>
              <span class="text-primary fs-5 ms-2">Add new Shift</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import BoxShiftSlots from "@/components/jobs/Edit/BoxSlot.vue";
import Inputmask from "inputmask";
import DeleteShiftModal from "@/components/jobs/Edit/DeleteShiftModal.vue";
import ShiftDurationWidget from "@/components/jobs/Widgets/ShiftDuration.vue";
import DeleteShiftButton from "@/components/jobs/Widgets/DeleteShiftButton.vue";
import ActionButtonIcon from "@/components/Icons/ActionButton.vue";
import { disabledDates } from "@/components/jobs/helpers";

export default defineComponent({
  name: "existing-shift-list",
  components: {
    BoxShiftSlots,
    DeleteShiftModal,
    ShiftDurationWidget,
    ActionButtonIcon,
    DeleteShiftButton
  },
  data: () => ({
    isAwaitingDeleteApproval: ""
  }),
  computed: {
    ...mapGetters("EditJobModule", ["existingShifts", "job"])
  },
  methods: {
    disabledDates,
    ...mapActions("EditJobModule", [
      "setShiftSkills",
      "flagForDelete",
      "removeDeletionFlag",
      "changeStart",
      "changeEnd",
      "changeDate"
    ]),
    handleBookButton(skills, shiftKey) {
      this.setShiftSkills({
        shiftKey,
        skills
      });
    },
    handleDeleteRequest(id) {
      this.isAwaitingDeleteApproval = id;
    },
    flagShiftForDeletion() {
      this.flagForDelete(this.isAwaitingDeleteApproval);
      this.isAwaitingDeleteApproval = "";
    },
    removeDeleteFlag(id) {
      this.removeDeletionFlag(id);
    },
    handleChangeStart(data) {
      this.changeDate(data);
    },
    hasBookedWorkers(id) {
      const shift = this.job.shifts.find(shift => shift.id == id);
      if (shift && shift.crew.total_booked > 0) {
        return true;
      } else {
        return false;
      }
    },
    canChangeStartDate(shiftID) {
      return !(
        this.hasBookedWorkers(shiftID) &&
        !this.$can("can_change_booked_shifts_start_date", "all")
      );
    },
    canDeleteShift(shiftID) {
      return !(
        this.hasBookedWorkers(shiftID) &&
        !this.$can("can_change_booked_shifts_start_date", "all")
      );
    }
  },
  mounted() {
    Inputmask({
      regex: "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
    }).mask("#startHour_edit");

    Inputmask({
      regex: "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
    }).mask("#endHour_edit");
  }
});
</script>

<style scoped>
.flaggedForDelete {
  text-decoration: line-through;
}
</style>
