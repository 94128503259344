<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header border-0 pt-5 px-6">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1"
              >Shift Configuration</span
            >

            <span class="text-muted mt-1 fw-bold fs-7"
              >Add, remove and modify shifts</span
            >
          </h3>
        </div>
        <div class="card-body py-3 px-0">
          <div class="row">
            <div class="col-12">
              <ShiftList
                v-if="!isEditingTemplate"
                :key="key"
                :isEditingTemplate="isEditingTemplate"
              ></ShiftList>
              <TemplateShiftList :key="key" v-else @updateList="this.key++"></TemplateShiftList>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ShiftList from "./ExistingShiftsList.vue";
import TemplateShiftList from "@/components/job-templates/TemplateShiftsList.vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "configure-existing-shifts-card",
  components: { ShiftList, TemplateShiftList },
  data: () => ({
    key: 0
  }),
  props: {
    isEditingTemplate: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions("EditJobModule", ["generateExistingShifts", "resetShifts"])
  },
  computed: {
    ...mapGetters("EditJobModule", [
      "existingShifts",
      "blueprint",
      "shifts",
      "job"
    ]),
    ...mapGetters("SingleJobTemplateModule", ["templateShifts"]),
    isNewShiftAdded() {
      if (!this.isEditingTemplate) return this.existingShifts.length;
      else return this.templateShifts;
    }
  },
  watch: {
    isNewShiftAdded() {
      this.key++;
    }
  },
  mounted() {
    if (!this.isEditingTemplate) this.generateExistingShifts();
  }
});
</script>
