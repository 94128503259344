<template>
  <div class="table-responsive">
    <table
      class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
    >
      <thead>
        <tr class="fw-bolder text-muted">
          <th class="min-w-50px w-175px px-9">Day</th>
          <th></th>
          <th class="w-100px">Shift Start</th>
          <th class="min-w-100px">Shift End</th>
          <th class="min-w-100px">Actions</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="shift in templateShifts" :key="shift.id">
          <tr>
            <td class="px-9">
              <div class="d-flex flex-column">
                <span>{{ shift.startDate }}</span>
              </div>
            </td>
            <td>
              <div
                class="form-check form-switch form-check-custom form-check-solid"
              >
                <input
                  class="form-check-input"
                  v-model="shift.status"
                  type="checkbox"
                  value=""
                  :id="'shiftToggle' + shift.id"
                />
              </div>
            </td>
            <td>
              <input
                v-model="shift.startHour"
                @change="changeStart(shift.id)"
                id="startHour_edit"
                class="form-control me-2 w-70px h-40px"
              />
            </td>
            <td class="text-end">
              <input
                v-model="shift.endHour"
                @change="changeEnd(shift.id)"
                id="endHour_edit"
                class="form-control me-2 w-70px h-40px"
              />
            </td>
            <td>
              <a
                class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                data-bs-toggle="collapse"
                :href="'#shiftCollapse' + shift.id"
              >
                <span class="svg-icon svg-icon-3">
                  <inline-svg
                    src="media/icons/duotone/General/Settings-1.svg"
                  />
                </span>
              </a>
              <button
                class="btn btn-icon btn-bg-danger btn-active-color-light btn-sm me-1"
                @click="handleDeleteShift(shift.id);"
              >
                <span class="svg-icon svg-icon-white svg-icon-3"
                  ><svg
                    version="1.1"
                    viewBox="0 0 24 24"
                    height="24px"
                    width="24px"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      xmlns="http://www.w3.org/2000/svg"
                      id="Stockholm-icons-/-General-/-Trash"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect
                        id="bound"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                      ></rect>
                      <path
                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                        id="round"
                        fill="#000000"
                        fill-rule="nonzero"
                      ></path>
                      <path
                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                        id="Shape"
                        fill="#000000"
                        opacity="0.3"
                      ></path>
                    </g></svg
                ></span>
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="12" class="p-0 m-0">
              <div class="collapse in" :id="'shiftCollapse' + shift.id">
                <div class="px-3 mb-4">
                  <BoxShiftSlots
                    :selectedSkills="shift.skills"
                    :shift="shift"
                    @booked="handleBookButton($event, shift.id)"
                    @deleteSkill="handleDeleteSkill"
                    :localShiftID="shift.id"
                  ></BoxShiftSlots>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <tr class="border-bottom-2">
          <td
            colspan="5"
            class="bg-hover-secondary"
            data-bs-toggle="modal"
            data-bs-target="#addNewShiftModal"
          >
            <div class="d-flex justify-content-center align-items-center">
              <span class="svg-icon svg-icon-3 svg-icon-primary"
                ><svg
                  version="1.1"
                  viewBox="0 0 24 24"
                  height="24px"
                  width="24px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    xmlns="http://www.w3.org/2000/svg"
                    id="Oval-5"
                    fill="#000000"
                    opacity="0.3"
                    cx="12"
                    cy="12"
                    r="10"
                  ></circle>
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                    id="Combined-Shape"
                    fill="#000000"
                  ></path></svg
              ></span>
              <span class="text-primary fs-5 ms-2">Add new Shift</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import BoxShiftSlots from "@/components/job-templates/TemplateBoxSlot.vue";
import Inputmask from "inputmask";

export default defineComponent({
  name: "template-shift-list",
  components: {
    BoxShiftSlots
  },
  data: () => ({
    isAwaitingDeleteApproval: ""
  }),
  computed: {
    ...mapGetters("SingleJobTemplateModule", ["templateShifts"])
  },
  methods: {
    ...mapActions("SingleJobTemplateModule", [
      "setShiftSkills",
      "deleteSkillsFromShifts",
      "deleteShift"
    ]),
    handleBookButton(skills, shiftKey) {
      this.setShiftSkills({
        shiftKey,
        skills
      });
    },
    handleDeleteShift(shiftID) {
      this.deleteShift(shiftID);
      this.$emit("updateList")
    },
    handleDeleteSkill(data) {
      this.deleteSkillsFromShifts(data);
    }
  },
  mounted() {
    Inputmask({
      regex: "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
    }).mask("#startHour_edit");

    Inputmask({
      regex: "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
    }).mask("#endHour_edit");
  }
});
</script>