<template>
  <div class="row mt-5">
    <div class="col-6">
      <SkillsDropdown @change="onSkillChange"></SkillsDropdown>
      <div class="table-responsive" v-if="categorySkillId">
        <table
          class="table table-row-dashed table-striped table-row-gray-300 align-middle"
        >
          <thead>
            <tr class="fw-bolder text-muted">
              <th class="min-w-50px w-175px px-9">Required skill</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="subSkill in subSkills" :key="subSkill.id">
              <td class="px-9">
                {{ subSkill.name }}
              </td>
              <td>
                <div
                  class="form-check form-switch form-check-custom form-check-solid"
                >
                  <input
                    class="form-control w-75px form-control-sm"
                    type="number"
                    @keydown.enter="ev => handleEnterKey(ev, subSkill.id)"
                    @change="
                      onChangeQtySubSkill($event, subSkill.id, localShiftID)
                    "
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-6">
      <button class="btn btn-success w-100 h-40px" @click="handleBookButton">
        Book
      </button>
      <div class="table-responsive">
        <table
          class="table table-row-dashed table-striped table-row-gray-300 align-middle"
        >
          <thead>
            <tr class="fw-bolder text-muted">
              <th class="min-w-50px w-175px px-9">Required skill</th>
              <th>Quantity</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="subSkill in shift.shiftSlots" :key="subSkill.id">
              <tr v-if="!subSkill.isDeleted">
                <td class="px-9">
                  {{ subSkill.name }}
                </td>
                <td>
                  <div
                    class="form-check form-switch form-check-custom form-check-solid"
                  >
                    <input
                      class="form-control w-75px form-control-sm"
                      type="number"
                      :value="subSkill.qty"
                      @change="
                        changeExistingSkill($event, subSkill.id, localShiftID)
                      "
                    />
                  </div>
                </td>
                <!-- <td>
                  <a
                    href="#"
                    class="btn btn-icon btn-bg-danger btn-active-color-light btn-sm me-1"
                  >
                    <span class="svg-icon svg-icon-white svg-icon-3">
                      <inline-svg src="media/icons/duotone/General/Trash.svg" />
                    </span>
                  </a>
                </td> -->
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row mb-3">
          <div class="col-6 d-flex flex-column" v-if="$can('job_create_can_edit_pm', 'all')">
            <label class="fs-7 fw-bold mb-1">Crew Handler:</label>
            <el-select
              v-model="details.project_manager_id"
              placeholder="Select crew handler"
              size="large"
              clearable
            >
              <el-option
                v-for="item in crewHandlers"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-6 d-flex flex-column">
            <label class="fs-7 fw-bold mb-1">Contact on location:</label>
            <el-select
              v-model="details.contact_on_location_id"
              size="large"
              :placeholder="
                companyCols.length > 0
                  ? 'Select contact on location'
                  : 'Select a company'
              "
              :disabled="companyCols.length > 0 ? false : true"
              clearable
            >
              <el-option
                v-for="item in companyCols"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6 d-flex flex-column">
            <label class="fs-7 fw-bold mb-1">Dresscode:</label>
            <el-select
              v-model="details.dresscode_id"
              placeholder="Select dresscodes"
              size="large"
              clearable
            >
              <el-option
                v-for="item in dresscodes"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-6">
            <label class="fs-7 fw-bold mb-1">Dresscode notes:</label>
            <textarea
              class="fs-6 ps-4 pt-1 w-100 border-secondary rounded-1 border-1"
              placeholder="Enter dresscode notes"
              rows="3"
              v-model.lazy="details.dresscode_notes"
            ></textarea>
          </div>
        </div>
        <div class="row pe-0">
          <div class="col-6">
            <label class="fs-7 fw-bold mb-1">Notes for workers:</label>
            <textarea
              class="fs-6 ps-4 pt-1 w-100 border-secondary rounded-1 border-1"
              placeholder="Enter worker notes"
              rows="3"
              v-model.lazy="details.notes_for_workers"
            ></textarea>
          </div>
          <div class="col-6">
            <label class="fs-7 fw-bold mb-1">Shift description:</label>

            <textarea
              class="fs-6 ps-4 pt-1 w-100 border-secondary rounded-1 border-1"
              placeholder="Enter shift description"
              rows="3"
              v-model.lazy="details.description"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable @typescript-eslint/camelcase */
import SkillsDropdown from "@/components/dropdown/SkillsDropdown.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "box-shift-slots",
  components: { SkillsDropdown },
  props: {
    selectedSkills: {
      type: Object,
      required: false
    },
    localShiftID: {
      type: Number,
      required: true
    },
    shift: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      categorySkillId: null,
      selectedSubSkills: {},
      bookedSkills: {},
      details: {
        project_manager_id: "",
        contact_on_location_id: "",
        dresscode_id: "",
        dresscode_notes: "",
        notes_for_workers: "",
        description: ""
      }
    };
  },
  mounted() {
    if (this.selectedSkills) {
      this.bookedSkills = Object.assign({}, this.selectedSkills);
      this.selectedSubSkills = this.bookedSkills;
    }
    this.fetchDropdownData();
    if (this.shift.details) {
      this.details = this.shift.details;
    }
  },
  computed: {
    ...mapGetters("ListsModule", [
      "skills",
      "crewHandlers",
      "dresscodes",
      "companyCols"
    ]),
    ...mapGetters("EditJobModule", ["existingShifts"]),
    subSkills() {
      if (!this.categorySkillId || !this.skills) return [];

      for (let i = 0; i < this.skills.length; i++) {
        if (this.skills[i].id === this.categorySkillId)
          return this.skills[i].children;
      }

      return [];
    },
    hasSelectedSkills() {
      return !_.isEmpty(this.selectedSubSkills);
    }
  },
  watch: {
    selectedSkills: {
      deep: true,
      handler(value) {
        if (value) {
          this.bookedSkills = Object.assign({}, value);
          this.selectedSubSkills = this.bookedSkills;
        }
      }
    },
    details: {
      deep: true,
      handler() {
        this.changeDetails({
          details: this.details,
          shiftID: this.localShiftID
        });
      }
    }
  },
  methods: {
    ...mapActions("ListsModule", ["fetchCrewHandlers", "fetchDresscodes"]),
    ...mapActions("EditJobModule", [
      "addNewShiftSlot",
      "changeShiftSlotPositions",
      "changeDetails",
      "deleteExistingSkill"
    ]),
    fetchDropdownData() {
      this.fetchCrewHandlers();
      this.fetchDresscodes();
    },
    onSkillChange(val) {
      this.selectedSubSkills = {};
      this.categorySkillId = val;
    },
    handleBookButton() {
      this.addNewShiftSlot({
        id: this.localShiftID,
        skill: this.selectedSubSkills
      });
    },
    getSkillData(skillId) {
      for (let i = 0; i < this.skills.length; i++) {
        if (this.skills[i].id === this.categorySkillId) {
          for (let j = 0; j < this.skills[i].children.length; j++) {
            if (parseInt(this.skills[i].children[j].id) === skillId)
              return this.skills[i].children[j];
          }
        }
      }
    },
    handleEnterKey(event, skillId) {
      if (event.target.value > 0) {
        this.selectedSubSkills[skillId] = Object.assign(
          {
            qty: event.target.value,
            shiftSlotID: `new_${Date.now()}`,
            isDeleted: false
          },
          this.getSkillData(skillId)
        );
      }
      this.handleBookButton();
    },
    onChangeQtySubSkill(event, skillId, shiftID) {
      if (event.target.value > 0) {
        this.selectedSubSkills[skillId] = Object.assign(
          {
            qty: event.target.value,
            shiftSlotID: `new_${Date.now()}`,
            isDeleted: false
          },
          this.getSkillData(skillId)
        );
      } else {
        delete this.selectedSubSkills[skillId];
      }
    },
    changeExistingSkill(event, skillId, shiftID) {
      if (event.target.value > 0) {
        this.changeShiftSlotPositions({
          qty: event.target.value,
          skillID: skillId,
          shiftID: shiftID
        });
        this.getSkillData(skillId);
      } else {
        this.deleteExistingSkill({ shiftID: shiftID, skill: skillId });
      }
    }
  }
};
</script>

<style scoped>
::placeholder {
  color: #c0c4cc;
}
</style>
